/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState, useRef } from 'react';
import SelectSearch /* , { fuzzySearch } */ from 'react-select-search';
import isDate from 'validator/lib/isDate';

import { FormContext } from '../context';

import loadComponents from '../../Loadable';

const Label = loadComponents('form-label');

function getYears() {
  const max = new Date().getFullYear() + 20;
  const min = max - 142;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push(i.toString());
  }
  return years;
}

const fields = [
  {
    name: 'day',
    placeholder: 'DD',
    options: Array.from(Array(31), (_, i) => {
      const number = (i + 1).toString();
      return number.length === 1 ? `0${number}` : number;
    }),
  },
  {
    name: 'month',
    placeholder: 'MM',
    options: Array.from(Array(12), (_, i) => {
      const number = (i + 1).toString();
      return number.length === 1 ? `0${number}` : number;
    }),
  },
  {
    name: 'year',
    placeholder: 'YYYY',
    options: getYears().reverse(),
  },
];

export default ({ field }) => {
  const { values, handleChange, failed } = useContext(FormContext);

  const select = useRef({ day: null, month: null, year: null });
  const open = useRef(false);

  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    open.current = active;
  }, [active]);

  const fieldVal = field.value.split('/');
  const [value, setValue] = useState({
    day: fieldVal[0],
    month: fieldVal[1],
    year: fieldVal[2],
  });

  useEffect(() => {
    if (values[field.id] !== undefined) {
      const date = values[field.id].split('/');
      setValue({ day: date[0], month: date[1], year: date[2] });
    }
  }, [values[field.id]]);

  useEffect(() => {
    if (value?.day && value?.month && value?.year) {
      const dateVal = `${value?.day}/${value?.month}/${value?.year}`;
      const isValid = isDate(dateVal, { format: 'DD/MM/YYYY' });
      if (isValid) {
        handleChange({ target: { name: field.name, value: dateVal } });
      }
      setError(!isValid);
    }
  }, [value]);

  const handleClick = e => {
    if (open.current) {
      const isSelect =
        select.current[open.current] &&
        select.current[open.current].contains(e.target);

      if (isSelect) {
        return;
      }
      setActive(false);
    }
  };

  const detectClick = e => {
    handleClick(e);
  };

  useEffect(() => {
    window.addEventListener('mousedown', detectClick, false);
    return () => {
      window.removeEventListener('mousedown', detectClick, false);
    };
  }, []);

  const updateValue = (item, val) => {
    setValue({ ...value, [item.name]: val });
    setActive(false);
  };

  const hasFailed =
    failed &&
    (values[field.id] === undefined || values[field.id] === undefined);

  const isInvalid = field.required && (error || hasFailed);

  return (
    <label htmlFor={field.id} onClick={e => e.preventDefault()}>
      <Label label={field.label} required={field.required} />
      <div style={dateWrapper}>
        {fields.map(item => (
          <div
            key={item.name}
            style={dateItem}
            ref={node => {
              select.current[item.name] = node;
            }}
          >
            <SelectSearch
              id={field.id}
              className={`select-search date ${isInvalid ? 'invalid' : ''}`}
              options={item.options.map(option => ({
                value: option,
                name: option,
              }))}
              onChange={val => updateValue(item, val)}
              value={[String(value[item.name])]}
              name={item.name}
              placeholder={item.placeholder}
              onFocus={() => setActive(item.name)}
              printOptions={active === item.name ? 'always' : 'auto'}
            />
          </div>
        ))}
      </div>
    </label>
  );
};

const dateWrapper = {
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,
  justifyContent: `space-between`,
};

const dateItem = {
  flex: `0 0 30%`,
};
